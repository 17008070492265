[data-component="note"] {
  @apply rounded border relative my-8;

  box-shadow: 1px 1px 1px rgb(0 0 0 / 10%);

  & .kind-icon {
    @apply mt-1 relative -top-px;
  }

  & .note-content div.highlighter-rouge {
    max-width: 100%;
  }

  & .note-footer {
    @apply text-sm text-right border-t;
  }

  &[data-note-theme="tip"] {
    @apply bg-sky-50/80 border-sky-200;

    & [data-component="icon"] {
      @apply text-sky-700;
    }

    & .note-footer {
      @apply bg-sky-100/50 border-sky-200 text-sky-700;
    }
  }

  &[data-note-theme="warn"] {
    @apply bg-amber-50/80 border-amber-300;

    & [data-component="icon"] {
      @apply text-amber-700;
    }

    & .note-footer {
      @apply bg-amber-100/50 border-amber-300 text-amber-700;
    }
  }

  &[data-note-theme="link"] {
    @apply bg-fuchsia-50/60 border-fuchsia-200;

    & [data-component="icon"] {
      @apply text-fuchsia-600;
    }

    & .note-footer {
      @apply bg-fuchsia-100/50 border-fuchsia-200 text-fuchsia-600;
    }
  }

  & .highlighter-rouge:last-child .highlight {
    margin-bottom: 0 !important;
  }

  & .prose :where(.prose > :first-child) {
    margin-top: 0 !important;
  }

  & .prose :where(.prose > :last-child) {
    margin-bottom: 0 !important;
  }

  & .prose :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
}
