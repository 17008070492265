[data-component="data-list"] {
  table {
    @apply bg-white w-full text-sm;
  }

  td {
    @apply py-1.5 px-4 align-top;

    & a {
      @apply underline;
    }
  }
}
