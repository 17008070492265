/* 
  Syntax Highlighting for Code Snippets

  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

  Other styles available eg. https://github.com/jwarby/jekyll-pygments-themes
  To use another style, delete all styles in this file and replace them with
  the new styles. Or create your own!

*/

pre.highlight {
  @apply px-4 py-3 text-sm overflow-auto;
  background-color: #f6f8fa;
}

pre.highlight {
  @apply bg-white text-gray-800 border border-gray-300 rounded font-mono;
}
pre.highlight code {
  @apply leading-relaxed;
}
pre.highlight .hll {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 6px 10px;
  border-radius: 3px;
}
pre.highlight .c {
  color: #999988;
  font-style: italic;
}
pre.highlight .err {
  /* color: #a61717;
          background-color: #e3d2d2; */
}
pre.highlight .k {
  font-weight: bold;
}
pre.highlight .o {
  font-weight: bold;
}
pre.highlight .cm {
  color: #999988;
  font-style: italic;
}
pre.highlight .cp {
  color: #999999;
  font-weight: bold;
}
pre.highlight .c1 {
  color: #999988;
  font-style: italic;
}
pre.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}
pre.highlight .gd {
  color: #000000;
  background-color: #ffdddd;
}
pre.highlight .gd .x {
  color: #000000;
  background-color: #ffaaaa;
}
pre.highlight .ge {
  font-style: italic;
}
pre.highlight .gr {
  color: #aa0000;
}
pre.highlight .gh {
  color: #999999;
}
pre.highlight .gi {
  color: #000000;
  background-color: #ddffdd;
}
pre.highlight .gi .x {
  color: #000000;
  background-color: #aaffaa;
}
pre.highlight .go {
  color: #888888;
}
pre.highlight .gp {
  color: #555555;
}
pre.highlight .gs {
  font-weight: bold;
}
pre.highlight .gu {
  color: #800080;
  font-weight: bold;
}
pre.highlight .gt {
  color: #aa0000;
}
pre.highlight .kc {
  font-weight: bold;
}
pre.highlight .kd {
  font-weight: bold;
}
pre.highlight .kn {
  font-weight: bold;
}
pre.highlight .kp {
  font-weight: bold;
}
pre.highlight .kr {
  font-weight: bold;
}
pre.highlight .kt {
  color: #445588;
  font-weight: bold;
}
pre.highlight .m {
  color: #009999;
}
pre.highlight .s {
  color: #dd1144;
}
pre.highlight .n {
  color: #333333;
}
pre.highlight .na {
  color: teal;
}
pre.highlight .nb {
  color: #0086b3;
}
pre.highlight .nc {
  color: #445588;
  font-weight: bold;
}
pre.highlight .no {
  color: teal;
}
pre.highlight .ni {
  color: purple;
}
pre.highlight .ne {
  color: #990000;
  font-weight: bold;
}
pre.highlight .nf {
  color: #990000;
  /* font-weight: bold; */
}
pre.highlight .nn {
  color: #555555;
}
pre.highlight .nt {
  color: navy;
}
pre.highlight .nv {
  color: teal;
}
pre.highlight .ow {
  font-weight: bold;
}
pre.highlight .w {
  color: #bbbbbb;
}
pre.highlight .mf {
  color: #009999;
}
pre.highlight .mh {
  color: #009999;
}
pre.highlight .mi {
  color: #009999;
}
pre.highlight .mo {
  color: #009999;
}
pre.highlight .sb {
  color: #dd1144;
}
pre.highlight .sc {
  color: #dd1144;
}
pre.highlight .sd {
  color: #dd1144;
}
pre.highlight .s2 {
  color: #dd1144;
}
pre.highlight .se {
  color: #dd1144;
}
pre.highlight .sh {
  color: #dd1144;
}
pre.highlight .si {
  color: #dd1144;
}
pre.highlight .sx {
  color: #dd1144;
}
pre.highlight .sr {
  color: #009926;
}
pre.highlight .s1 {
  color: #dd1144;
}
pre.highlight .ss {
  color: #990073;
}
pre.highlight .bp {
  color: #999999;
}
pre.highlight .vc {
  color: teal;
}
pre.highlight .vg {
  color: teal;
}
pre.highlight .vi {
  color: teal;
}
pre.highlight .il {
  color: #009999;
}
pre.highlight .gc {
  color: #999;
  background-color: #eaf2f5;
}
