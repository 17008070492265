[data-component="lede"] {
  @apply text-lg;

  & > :first-child {
    @apply !mt-0;
  }

  & > :last-child {
    @apply !mb-0;
  }

  /* &:after {
    content: "";
    @apply block w-20 border-b my-6 border-gray-300;
  } */
}
