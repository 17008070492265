[data-component="method"] {
  & .prose :where(.prose > :first-child) {
    margin-top: 0 !important;
  }

  & .prose :where(.prose > :last-child) {
    margin-bottom: 0 !important;
  }

  & .prose :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  p code.highlighter-rouge {
    @apply bg-gray-100;
  }

  & h4 {
    @apply text-xs uppercase tracking-wider mb-2 text-gray-400;
  }
}
